import {configureStore} from "@reduxjs/toolkit"
import userReducer from '../slices/UserSlice'
import {api} from "./api";

export const store = configureStore({
    reducer: {
        user: userReducer,
        [api.reducerPath]: api.reducer
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware().concat(api.middleware)
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;