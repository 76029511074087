import React, {useState} from 'react'
import {CheckBoxComponentProps} from "../interfaces/props/CheckBoxComponentProps";
import {v4 as uuid} from 'uuid';

export function CheckBoxComponent({name, label, value}: CheckBoxComponentProps) {
    const inputID = uuid()
    const [checked, setChecked] = useState(false);
    return (
            <label
                className={'checkbox ' + (checked ? '' : 'un') + 'checked' }
                htmlFor={inputID}
            >
                <div>
                    <span>{label}</span>
                </div>
                <input
                    type="checkbox"
                    className="hidden"
                    defaultChecked={checked}
                    onChange={() => setChecked((state) => !state)}
                    id={inputID}
                    value={value}
                    name={name}
                />
            </label>
    )
}