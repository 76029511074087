import React, {useEffect, useState} from "react";
import {InputImageChange} from "../../types/InputImageChange";

export function useInputImageComponent(onChange?: InputImageChange){
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [preview, setPreview] = useState<string>('')

    function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>){
        if (!event.target.files || event.target.files.length === 0) {
            setSelectedFile(null)
            return
        }
        const file = event.target.files[0]
        setSelectedFile(file)
        if (onChange) onChange(file)
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview('')
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    return { selectedFile, preview, onChangeHandler }
}