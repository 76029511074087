import React, {useState} from "react";
import {LoginFormSubmit} from "../../types/LoginFormSubmit";

export function useLoginFormComponent(onSubmit: LoginFormSubmit){
    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    function loginHandler(event: React.ChangeEvent<HTMLInputElement>){
        setLogin(event.target.value)
    }
    function passwordHandler(event: React.ChangeEvent<HTMLInputElement>){
        setPassword(event.target.value)
    }
    function submitHandler(event: React.FormEvent){
        event.preventDefault()
        onSubmit(login, password)
    }
    return { login, password, loginHandler, passwordHandler, submitHandler }
}