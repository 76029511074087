import React from 'react'
import {ErrorMessageComponentProps} from "../interfaces/props/ErrorMessageComponentProps";

export function ErrorMessageComponent({error}: ErrorMessageComponentProps) {
    return (
        <>
            <div className="error_container">
                { error &&
                    <p>{error}</p>
                }
            </div>
        </>

    )
}