import React from 'react'
import {CheckWallpaperComponentProps} from "../interfaces/props/CheckWallpaperComponentProps";
import {ModalComponent} from "./ModalComponent";
import {useCheckWallpaperComponentHook} from "../hooks/components/CheckWallpaperComponentHook";

export function CheckWallpaperComponent({file, onClose}: CheckWallpaperComponentProps) {

    const {showModal, onCloseModal} = useCheckWallpaperComponentHook(file, onClose)

    return (
        <ModalComponent show={showModal} onClose={onCloseModal}>
            <span>H</span>
        </ModalComponent>
    )
}