import React, {useEffect, useState} from "react"
import {InputImageComponent} from "../components/InputImageComponent";
import {CheckWallpaperComponent} from "../components/CheckWallpaperComponent";
import {useCategoriesQuery} from '../store/api'
import {useAuth} from "../hooks/shared/Auth";
import {isArray} from "util";
import {CategoryListModel} from "../interfaces/models/CategoryListModel";
import {CheckBoxComponent} from "../components/CheckBoxComponent";
import {CategoryListComponent} from "../components/CategoryListComponent";
import {useFormData} from "../hooks/shared/FromData";
import axios, {AxiosError} from "axios";
import {SimilarImages} from "../interfaces/shared/SimilarImages";
import {HttpPath, useHttp} from "../hooks/shared/Http";

export function CreateWallpaper() {
    const [imageToCheck, setImageToCheck] = useState<File | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const { getFromEvent, resetForm } = useFormData()
    const { token } = useAuth()
    const [ progress, setProgress ] = useState<boolean>(false)
    const { url, getHeadersToAxios, } = useHttp()
    const { isLoading, data, error } = useCategoriesQuery(token, {
        skip: token === null
    })

    function submitHandler(event: React.FormEvent) {
        event.preventDefault()
        const formData = getFromEvent(event)
        resetForm(event)
        const config = {
            headers: getHeadersToAxios(token)
        }

        // @ts-ignore
        for(let key of formData.entries()){
            console.log(key[0] + ', ' + key[1])
        }

        setProgress(true)
        axios.post<any>(url(HttpPath.wallpapers), formData, config)
            .then(response => {
                console.log(response)
                setProgress(false)
            })
            .catch( (error: Error | AxiosError) => {
                setProgress(false)
                setErrorMessage('d')
                if (axios.isAxiosError(error)) {
                    console.log(error)
                }
                setTimeout(()=>{ setErrorMessage(undefined)}, 800)
            })
    }

    if (progress){
        return (
            <span>Обрабатывается</span>
        )
    } else if (!errorMessage) {
        return (
            <>
                <div className="wallpapers">
                    <h1>Create Wallpaper</h1>
                    <div>
                        <form onSubmit={submitHandler} id="wallpapers_form">
                            <div className='split_box'>
                                <div className='left'>
                                    <span>Оригинал</span>
                                    <InputImageComponent
                                        name="original"
                                        onChange={(file) => {
                                            setImageToCheck(file)
                                        }}
                                    />
                                    <span>После обработки</span>
                                    <InputImageComponent
                                        name="compressed"
                                    />
                                    <div className="inputs">
                                        <input className="input" type="text" name="author" placeholder="author"/>
                                        <input className="input" type="text" name="url" placeholder="url"/>
                                        <input className="input" type="text" name="source" placeholder="source"/>
                                    </div>

                                </div>
                                <div className='right'>
                                    <CategoryListComponent name='categories' categories={data}/>
                                </div>
                            </div>
                            <button>
                                Отправить
                            </button>
                        </form>
                    </div>
                </div>
                <CheckWallpaperComponent file={imageToCheck} onClose={()=>{setImageToCheck(undefined)}}/>
            </>

        )
    } else {
        return (
            <span>Что-то пошло не так</span>
        )
    }
}