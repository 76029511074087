import React, {useEffect, useState} from 'react'
import {ModalComponentProps} from "../interfaces/props/ModalComponentProps";

export function ModalComponent({ children, show, onClose }: ModalComponentProps) {

    const [showElement, setShowElement] = useState<Boolean>(show)
    const [visible, setVisible] = useState<Boolean>(show)

    useEffect(() => {
        if (show === showElement) return
        if (show){
            setShowElement(show)
            setTimeout(()=>{
                setVisible(show)
            }, 10)

        } else {
            setVisible(show)
            setTimeout(()=>{
                setShowElement(show)
            }, 300)
        }
    }, [show])

    return (
        <>
            {showElement &&
                <div className="modal">
                    <div className={'box' + ' ' + (visible ? 'show' : 'hide')}>
                        <div className="background" onClick={onClose}/>
                        <div className="content">
                            {children}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}