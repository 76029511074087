import {Navigate, Route, Routes} from "react-router-dom"
import {HomePage} from "./pages/HomePage"
import {AuthPage} from "./pages/AuthPage"
import {CreateWallpaper} from "./pages/CreateWallpaper";

export const useRoutes = (isAuth: boolean) => {
    if (isAuth) {
        return (
            <Routes>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/wallpapers/create' element={<CreateWallpaper/>}/>
                <Route path='*' element={<Navigate to="/" replace={true} />} />
            </Routes>
        )
    } else {
        return (
            <Routes>
                <Route path='/login' element={<AuthPage/>}/>
                <Route path='*' element={<Navigate to="/login" replace={true} />} />
            </Routes>
        )
    }
}