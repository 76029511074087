import {UserState} from "../../interfaces/shared/UserState";

export function useLocalStorage() {

    const keys = {
        id: "user_id",
        token: "user_api_token",
        name: "user_name",
        role: "user_role"
    }

    function getLocalUser(): UserState | undefined {
        const token: string | null = localStorage.getItem(keys.token)
        if (token === null) return
        const id: number = Number(localStorage.getItem(keys.id))
        if (isNaN(id)) return
        const name: string  = localStorage.getItem(keys.name) || ''
        const role: string  = localStorage.getItem(keys.role) || ''
        return { name, token, id, role }
    }
    
    function removeAll(){
        localStorage.removeItem(keys.token)
        localStorage.removeItem(keys.id)
        localStorage.removeItem(keys.name)
        localStorage.removeItem(keys.role)
    }

    function setLocalUser(user: UserState): void {
        if (typeof user.token === "string") {
            localStorage.setItem(keys.token, user.token)
        }
        localStorage.setItem(keys.name, user.name)
        localStorage.setItem(keys.role, user.role)
        localStorage.setItem(keys.id, String(user.id))
    }

    return {getLocalUser, setLocalUser, removeAll}
}