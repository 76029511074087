import React from "react"

export function useFormData() {
    function getFromEvent(event: React.FormEvent): FormData {
        // @ts-ignore
        return new FormData(event.target)
    }

    function resetForm(event: React.FormEvent) {
        // @ts-ignore
        event.target.reset()
    }

    return { getFromEvent, resetForm }
}