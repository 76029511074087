export enum HttpPath {
    login = 'login',
    checkImage = 'wallpapers/check',
    categories = 'categories',
    wallpapers = 'wallpapers'
}

export function useHttp() {

    const host: string = 'myrocknwall.com'
    const apiPrefix: string = 'api/v2'
    const serverScheme: string = "https"

    function url(path: HttpPath | null = null, prefix: string = apiPrefix, scheme: string = serverScheme): string {
        const base = scheme + '://' + host + '/' + prefix
        if (path) {
            return base + '/' + path
        } else {
            return base
        }
    }

    function getHeadersToAxios(token: string | null): any {
        return {
            //'Content-Type': 'application/json; charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer ' + (token || '')
        }
    }

    return {host, apiPrefix, serverScheme, url, getHeadersToAxios}
}