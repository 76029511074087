import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {CategoryListModel} from "../interfaces/models/CategoryListModel";

export const api = createApi({
    reducerPath: 'rocknwall/api',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://myrocknwall.com/api/v2',
        prepareHeaders: (headers) => {
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return headers;
        }
    }),
    endpoints: build => ({
        categories: build.query<CategoryListModel[], string | null>({
            query: (token: string | null) => ({
                url: 'categories/list',
                headers: getHeaders(token)
            })
        })
    })
})


function getHeaders(token: string | null = null): Headers {
    const headers = new Headers()
    if (token) headers.append('Authorization', ('Bearer ' + token))
    return headers
}

export const { useCategoriesQuery } = api