import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {UserState} from "../interfaces/shared/UserState"
import {RootState} from "../store";

const defaultUser: UserState = {
    id: 0,
    role: '',
    name: 'sdf',
    token: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState: defaultUser,
    reducers: {
        setUser(state, action: PayloadAction<UserState>){
            state.id = action.payload.id
            state.role = action.payload.role
            state.name = action.payload.name
            state.token = action.payload.token
        },
        removeUser(state){
            state.id = 0
            state.role = ''
            state.name = ''
            state.token = null
        }
    }
})

export const {setUser, removeUser} = userSlice.actions
export const userSelector = (state: RootState) => state.user
export default userSlice.reducer