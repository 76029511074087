import {useEffect} from "react";
import {useAuth} from "./hooks/shared/Auth";
import {useDispatch} from "react-redux";
import {setUser} from "./slices/UserSlice";
import {UserState} from "./interfaces/shared/UserState";
import {useLocalStorage} from "./hooks/shared/LocalStorage";
import {useRoutes} from "./routes";

function App() {
    const { isAuth } = useAuth()
    const routes = useRoutes(isAuth)
    const { getLocalUser, removeAll } = useLocalStorage()
    const dispatch = useDispatch();
    useEffect(() => {
        //removeAll()
        const localUser: UserState | undefined = getLocalUser()
        if (localUser !== undefined) dispatch(setUser(localUser))
    }, [])

    return (
        routes
    )
}

export default App
