import React, {useEffect, useState} from "react";
import {HttpPath, useHttp} from "../shared/Http";
import {SimilarImages} from "../../interfaces/shared/SimilarImages";
import axios, {AxiosError} from "axios";

export function useCheckWallpaperComponentHook(file?: File, onClose?: () => void){
    const { url } = useHttp()
    const [similarImages, setSimilarImages] = useState<SimilarImages>({image_names: []})
    const [showModal, setShowModal] = useState<Boolean>(false)

    useEffect(() => {
        if (similarImages.image_names.length !== 0) {
            setShowModal(true)
        }
    }, [similarImages])

    function onCloseModal(){
        if (onClose) onClose()
        setShowModal(false)
    }

    useEffect(() => {
        if (!file) return
        check(file)
    }, [file])

    function check(file: File){
        const formData = new FormData()
        formData.append('image', file)
        axios.post<SimilarImages>(url(HttpPath.checkImage), formData)
            .then(response => {
                if (response.data.image_names.length !== 0){
                    console.log(response.data.image_names[0])
                    setSimilarImages(response.data)
                }
            })
            .catch( (error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                    console.log(error)
                }
            })
    }
    return { similarImages, showModal, setShowModal, check, onCloseModal }
}