import React from "react"
import {LoginComponentProps} from "../interfaces/props/LoginComponentProps";
import {useLoginFormComponent} from "../hooks/components/LoginFormComponentHook";
import {ErrorMessageComponent} from "./ErrorMessageComponent";

export function LoginFormComponent({ errorMessage, onSubmit } : LoginComponentProps) {
    const {login, password, loginHandler, passwordHandler, submitHandler} = useLoginFormComponent(onSubmit)
    return (
        <form className="login" onSubmit={submitHandler}>
            <input type="text"
                   className="input"
                   value={login}
                   placeholder="Enter login"
                   onChange={loginHandler}
            />
            <input type="password"
                   className="input"
                   value={password}
                   placeholder="Enter password"
                   onChange={passwordHandler}
            />
            {errorMessage.length !== 0 && <ErrorMessageComponent error={errorMessage} />}
            <button
                type="submit"
                className="button"
            >
                Login
            </button>
        </form>
    )
}