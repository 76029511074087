import React, {useState} from "react"
import {LoginFormSubmit} from "../types/LoginFormSubmit";
import {LoginFormComponent} from "../components/LoginFormComponent";
import {useAuthPageHook} from "../hooks/pages/AuthPageHook";
import {useHttp} from "../hooks/shared/Http";

export function AuthPage() {
    const [loginError, setLoginError] = useState<string>('')
    const { auth } = useAuthPageHook()

    const loginHandler: LoginFormSubmit = (login: string, password: string) => {
        setLoginError('')
        auth(login, password)
    }

    return (
        <LoginFormComponent errorMessage={loginError} onSubmit={loginHandler} />
    )
}