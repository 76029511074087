import React from 'react'
import {InputImageComponentProps} from "../interfaces/props/InputImageComponentProps";
import {useInputImageComponent} from "../hooks/components/InputImageComponentHook";
import {v4 as uuid} from "uuid";

export function InputImageComponent({ onChange, name }: InputImageComponentProps) {
    const { selectedFile, preview, onChangeHandler } = useInputImageComponent(onChange)
    const inputID = uuid()
    return (

        <label
            className={'image ' + (selectedFile ? 'selected' : 'unselected')}
            htmlFor={inputID}
        >


            <div>
                <span>{selectedFile ? 'Изменить' : 'Выбрать'}</span>
                {selectedFile &&  <img src={preview}  alt="preview"/> }
            </div>




            <input
                type="file"
                className="hidden"
                id={inputID}
                onChange={onChangeHandler}
                name={name}
            />
        </label>
    )
}