import React, {useEffect} from "react"
import {Link} from "react-router-dom";

export function HomePage() {
    return (
        <div className="container">
            <Link to='/wallpapers/create'>
                Create
            </Link>
        </div>
    )
}