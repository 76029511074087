import axios, {AxiosError} from "axios";
import {HttpPath, useHttp} from "../shared/Http";
import {UserState} from "../../interfaces/shared/UserState";
import {setUser} from "../../slices/UserSlice";
import {useDispatch} from "react-redux";
import {useLocalStorage} from "../shared/LocalStorage";


export function useAuthPageHook(){

    const { url } = useHttp()
    const dispatch = useDispatch();
    const { setLocalUser } = useLocalStorage()

    function auth(login: string, password: string){
        const formData = new FormData();
        formData.append('login', login)
        formData.append('password', password)
        axios.post<UserState>(url(HttpPath.login), formData)
            .then(response => {
                setLocalUser(response.data)
                dispatch(setUser(response.data))
            })
            .catch( (error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                    console.log(error)
                }
            })
    }
    return { auth }
}