import React, {useState} from 'react'
import {CheckBoxComponentProps} from "../interfaces/props/CheckBoxComponentProps";
import {v4 as uuid} from 'uuid';
import {CategoryListModel} from "../interfaces/models/CategoryListModel";
import {CheckBoxComponent} from "./CheckBoxComponent";
import {CategoryListComponentProps} from "../interfaces/props/CategoryListComponentProps";

export function CategoryListComponent({name, categories}: CategoryListComponentProps) {

    function getCategoryName(category: CategoryListModel): string{
        const values = category.language_values.filter(item => { return item.language === '0'} )
        const value = values[0]
        if (value) return value.value
        else { return 'undefined'}
    }


    return (
        <>
            {
                categories?.map(category => (
                    <CheckBoxComponent
                        key={category.id}
                        name={name + '[]'}
                        value={String(category.id)}
                        label={getCategoryName(category)}
                    />
                ))
            }
        </>
    )
}